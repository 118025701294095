@media screen and (max-width: 990px) and (orientation: portrait) {
  .container {
    width: 576px;
    height: 1024px;
  }
  footer .progress li {
    display: none;
  }
  footer .progress li.active {
    display: block;
    width: 100%;
  }
  footer .lmore {
    display: none;
  }
  .final-step footer .lmore {
    display: block;
  }
  .logo {
    font-size: 28px;
    line-height: 36px;
    width: 300px;
  }
  .text-content {
    top: 300px;
  }
  .text-content h1 {
    font-size: 32px;
    margin-right: 50%;
  }
  .text-content h2 {
    font-size: 32px;
    margin-top: 12px;
    margin-right: 9%;
    line-height: 1.25em;
  }
  .text-content p {
    margin-right: 8%;
  }
  .text-content.narrow {
    top: 120px;
    max-width: 100%;
    p {
      font-size: 22px;
    }
  }
  .text-content.list {
    top: 140px;
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 400px;
      li {
        width: 50%;
        margin-bottom: 20px;
      }
    }
  }
  .text-content-aside {
    top: 320px;
    left: 290px;
    right: 0px;
    max-height: 580px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .text-content-aside.subsys {
    top: 320px;
  }
  .text-content.sections {
    font-size: 26px;
    top: 140px;
    display: flex;
    justify-content: left;
    max-width: 100%;
    width: 100%;
    >div {
      line-height: 36px;
      width: 45%;
    }
  }
  .end-message.active .fline {
    width: auto;
    white-space: normal;
  }
  .foot-social {
    position: absolute;
    left: 0;
    bottom: 100px;
    flex-wrap: wrap;
    width: 100%;
    label {
      display: block;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      font-size: 30px;
      margin-bottom: 40px;
      margin-right: 0;
    }
    a {
      width: 0;
      margin-left: 50px;
      margin-right: 45px;
/*       margin-left: 50px; */
      font-size: 0.1;
/*       margin-right: 0; */
      color: transparent;
      &:first-of-type {
/*         margin-left: 40px; */
      }
      &:before {
        width: 44px;
        height: 44px;
      }
    }
  }
  .popup .pop-inner .detail {
    line-height: 30px;
  }
}
