.fadebottom-leave-active {
  transition: 1s;
}
.fadebottom-enter-active {
  transition: 1s;
}
.fadebottom-leave-to,
.fadebottom-enter {
  transform: translateY(60px);
  opacity: 0;
}


.popupanim-leave-active {
  transition: .5s;
  >div {
    transition: .5s;
  }
}
.popupanim-enter-active {
  transition: .5s;
  >div {
    transition: .5s;
  }
}
.popupanim-leave-to,
.popupanim-enter {
  opacity: 0;
  >div {
    transform: translateY(60px);
  }
}
.end-message {
  .fline {
    margin: 0 auto;
/*     width: 0; */
/*     width: 720px; */
    opacity: 0;
    transition: 1s;
    transition-timing-function: linear;
  }
  .sline {
    width: 0;
    opacity: 0;
    transition: 2s;
    transition-delay: 1s;
  }
  a.target {
    transform: scale(0);
    transform-origin: 50% 50%;
    opacity: 0;
    transition-timing-function: ease-out;
    &.visible {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.active {
    .fline {
/*       width: 720px; */
      opacity: 1;
    }
    .sline {
      width: auto;
      opacity: 1;
    }
  }
}


.step {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.human {
  position: absolute;
  >.human-digestive {
    background: url("../img/animsvg/01-human-dig.svg") no-repeat;
    background-size: cover;
    position: absolute;
    top: 36px;
    left: 88px;
    width: 85px;
    height: 202px;
    opacity: 0;
  }
  >.human-lintestine {
/*     background: url("../img/animsvg/01-human-large-int.svg") no-repeat; */
/*     background-size: cover; */
    position: absolute;
    top: 225px;
    left: 88px;
    width: 91px;
    height: 103px;
    z-index: 1;
  }
  >.human-sintestine {
/*     background: url("../img/animsvg/01-human-small-int.svg") no-repeat; */
/*     background-size: cover; */
    position: absolute;
    top: 244px;
    left: 105px;
    width: 56px;
    height: 50px;
  }
}


.step-1 {
  >div {
    position: absolute;
    margin: 0;
  }
  @keyframes step1rotate {
    from { transform: rotate(0deg); }
    50% { transform: rotate(10deg); }
    to { transform: rotate(0deg); }
  }
  .el-egg {
    background: url("../img/animsvg/00-egg.svg") no-repeat 50% 50%;
    width: 121px;
    height: 122px;
    left: 430px;
    top: 72px;
  }
  .el-milk {
    background: url("../img/animsvg/00-milk.svg") no-repeat 50% 50%;
    width: 212px;
    height: 264px;
    left: 655px;
    top: 70px;
  }
  .el-peanut {
    background: url("../img/animsvg/00-peanut-whole.svg") no-repeat 50% 50%;
    width: 78px;
    height: 79px;
    left: 752px;
    top: 408px;
  }
  .el-peanut-1,
  .el-peanut-2,
  .el-peanut-3 {
    background: url("../img/animsvg/00-peanut.svg") no-repeat 50% 50%;
    width: 29px;
    height: 27px;    
  }
  .el-peanut-1 {
    left: 888px;
    top: 426px;
  }
  .el-peanut-2 {
    left: 838px;
    top: 475px;
  }
  .el-peanut-3 {
    left: 908px;
    top: 495px;
  }
  .el-egg,
  .el-milk,
  .el-peanut,
  .el-peanut-1,
  .el-peanut-2,
  .el-peanut-3 {
    opacity: 0;
    transform: translateX(300px);
  }
  .el-egg.loaded,
  .el-milk.loaded,
  .el-peanut.loaded,
  .el-peanut-1.loaded,
  .el-peanut-2.loaded,
  .el-peanut-3.loaded {
    transform: translateX(0);
    opacity: 1;
    transition: 1s;
  }
  .el-egg.loaded {
    transition-delay: 1s;
    animation: step1rotate 1.6s 2s infinite;
  }
  .el-milk.loaded {
    transition-delay: 1.25s;
    animation: step1rotate 2s 2.25s infinite;
  }
  .el-peanut.loaded {
    transition-delay: 1.5s;
    animation: step1rotate 1.8s 2.5s infinite;
  }
  .el-peanut-1.loaded { 
    transition-delay: 1.5s;
    animation: step1rotate 1.8s 2.5s infinite;
  }
  .el-peanut-2.loaded { 
    transition-delay: 1.5s;
    animation: step1rotate 1.8s 2.5s infinite;
  }
  .el-peanut-3.loaded { 
    transition-delay: 1.5s;
  }
  
  .el-human {
    position: absolute;
    opacity: 0;
    top: 200px;
    left: 600px;
    transform: scale(4);
    transform-origin: 0% 0%;
  }
  
  &.step-1-1 {
    .el-egg, .el-milk {
      margin-top: -200px;
      opacity: 0;
    }
  }
  &.step-1-1-1 {
    .el-peanut, .el-peanut-1, .el-peanut-2 {
      margin-left: -600px;
      margin-top: -80px;
      opacity: 1;
    }
  }
  &.step-1-1-2, &.step-1-2 {
    .el-peanut, .el-peanut-1, .el-peanut-2 {
      margin-left: -700px;
      margin-top: -85px;
      opacity: 0;
    }
  }
  &.step-1-1-1 {
    .el-peanut-3 {
      transform: translate(-600px, -80px);
    }
    .el-human {
      left: 400px;
      opacity: 0.5;
    }
  }
  &.step-1-1-2 {
    .el-peanut-3 {
      transform: translate(-620px, -80px);
    }
    .el-human {
      left: 50px;
      opacity: 1;
      .human-digestive {
        opacity: 1;
      }
    }
  }
  &.step-1-1-3 {
    .el-peanut-3 {
      transform: translate(-460px, -73px);
    }
  }
  &.step-1-1-4 {
    .el-peanut-3 {
      transform: translate(-343px, -50px);
    }
  }
  &.step-1-1-5 {
    .el-peanut-3 {
      transform: translate(-342px, 350px);
    }
    transform: scale(0.4) translateY(-50%);
  }
  &.step-1-1-6 {
    .el-peanut-3 {
      transform: translate(-250px, 450px);
    }
  }
  &.step-1-1-7 {
    .el-peanut-3 {
      transform: translate(-250px, 510px);
      background-image: url("../img/animsvg/02-food.svg");
      background-size: contain;
    }
  }
  &.step-1-1-8 {
    .el-peanut-3 {
      transform: translate(-400px, 515px);
    }
  }
  &.step-1-1-9 {
    .el-peanut-3 {
      transform: translate(-410px, 550px);
    }
  }
  &.step-1-1-10 {
    .el-peanut-3 {
      transform: translate(-410px, 560px);
    }
  }
  &.step-1-1-11 {
    .el-peanut-3 {
      transform: translate(-380px, 580px);
    }
  }
  &.step-1-1-12 {
    .el-peanut-3 {
      transform: translate(-310px, 580px);
    }
  }
  &.step-1-1-13 {
    .el-peanut-3 {
      transform: translate(-330px, 710px);
    }
  }
  
  &.step-1-2 {
    .el-peanut-3 {
      background-image: url("../img/animsvg/02-food.svg");
      background-size: contain;
      transform: translate(-330px, 710px);
    }
    .el-human {
      left: 50px;
      opacity: 1;
      .human-digestive {
        opacity: 1;
      }
    }
    transform: scale(0.4) translateY(-50%);
    transform: scale(0.4) translateY(-90%);
  }
  
  
  
  
  
  
  .el-dot {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: white;
    top: 1200px;
    left: 600px;
    opacity: 0;
  }
  .el-triangle {
    position: absolute;
    margin: 0;
    border: 200px solid;
    border-color: transparent transparent rgba(255,255,255,0.3) rgba(255,255,255,0.3);
    width: 0px;
    height: 0px;
    top: 830px;
    left: 600px;
    opacity: 0;
    transform: scale(0) skew(-10deg, -10deg);
    transform-origin: 0% 100%;
  }
  .el-circle {
    overflow: hidden;
    border: 10px solid #eee;
    border-radius: 410px;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,#282777 100%);
    background-color: #2c58a6;
/*     background-color: #842268; */
    width: 750px;
    height: 750px;
    top: 400px;
    left: 660px;
    transform: scale(0);
    transform-origin: -60px 830px;
    opacity: 0;
    span {
      text-transform: uppercase;
      color: white;
      font-size: 32px;
      font-weight: bold;
      display: block;
      position: absolute;
      transition: 1s;
      &::before {
        content: "";
        width: 180px;
        height: 2px;
        background: white;
        position: absolute;
        margin: 10px 0 0 10px;
      }
      &::after {
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 8px;
        background: white;
        position: absolute;
      }
    }
    >div { /* TODO */
/*       margin: -200px 100px; */
      transition: 1s margin, 1s top, 1s left !important;
    }
    .el-lining {
      position: absolute;
      background: url("../img/animsvg/02-gutlining.svg") no-repeat;
      background-size: contain;
      width: 937px;
      height: 695px;
      top: 120px;
      left: -30px;
      span {
        top: 200px;
        left: 200px;
        &::before, &::after {
          top: -70px;
          left: 170px;
        }
        &::before {
          transform: rotate(130deg);
          width: 70px;
          transform-origin: 0% 0%;
        }
      }
    }
    .el-food {
      position: absolute;
      background: url("../img/animsvg/02-food.svg") no-repeat;
      background-size: contain;
      width: 75px;
      height: 65px;
      top: 100px;
      left: 500px;
      span {
        top: -50px;
        left: -220px;
        &::before, &::after {
          top: 60px;
          left: 220px;
        }
        &::before {
          top: 60px;
          left: 220px;
          transform: rotate(200deg);
          width: 50px;
          transform-origin: 0% 0%;
        }
      }
    }
    .el-neuron {
      position: absolute;
      top: 350px;
      left: 250px;
      svg {
        width: 400px;
        height: 346px;
        transform: rotate(240deg);
      }
      span {
        font-size: 23px;
        text-indent: 30px;
        top: 20px;
        top: 35px;
        left: -150px;
        left: -125px;
        &::before, &::after {
          top: 80px;
          top: 65px;
          left: 240px;
          left: 215px;
        }
        &::before {
          transform: rotate(200deg);
          width: 120px;
          transform-origin: 0% 0%;
        }
      }      
    }
    .el-peptide {
      opacity: 0;
      background: #fb174b;
      width: 25px;
      height: 25px;
      border-radius: 13px;
      position: absolute;
      top: 575px;
      left: 280px;
      span {
        top: 80px;
        left: 50px;
        &::before, &::after {
          top: -60px;
          left: -40px;
        }
        &::before {
          transform: rotate(60deg);
          width: 50px;
          transform-origin: 0% 0%;
        }
      }      
    }
    .el-icl {
      svg {
        width: 214px;
        height: 144px;
      }
      position: absolute;
      top: 655px;
      left: -40px;
      span:first-of-type {
        top: -100px;
        left: 0px;
        &::before, &::after {
          top: 100px;
          left: 40px;
        }
        &::before {
          transform: rotate(-100deg);
          width: 50px;
          transform-origin: 0% 0%;
        }
      }      
      span:last-of-type {
        top: 115px;
        left: 230px;
        &::before, &::after {
          top: -60px;
          left: -40px;
        }
        &::before {
          transform: rotate(60deg);
          width: 50px;
          transform-origin: 0% 0%;
        }
      }
      .immune-response {
        position: absolute;
        top: 72px;
        left: 113px;
        width: 0;
        height: 0;
        strong {
          position: absolute;
          top: -30px;
          left: 150px;
          transform-origin: -150px 30px;
          white-space: nowrap;
          color: #fb174b;
          font-weight: bold;
          font-size: 32px;
          text-transform: uppercase;
        }
        opacity: 0;
        visibility: hidden;
      }
    }
    
  }
  
  &.step-1-3 {
    .el-icl span,
    .el-neuron span {
      opacity: 0;
    }
  }
  
  &.step-1-4 {
    .el-lining span,
    .el-food span {
      opacity: 0;
    }
    .el-neuron span {
      opacity: 1;
    }
  }
  &.step-1-5 {
    .el-peptide span,
    .el-neuron span {
      opacity: 0;
    }
    .el-icl span {
      opacity: 1;
    }
  }
  
  
  .human {
    .cough {
      position: absolute;
      top: 40px;
      left: 60px;
      
      em {
        display: block;
        border: 3px solid;
        border-color: transparent transparent white white;
        border-width: 3px 5px;
        left: 15px;
        top: 10px;
        position: absolute;
        transform: skewX(-10deg) scale(0.5);
        transform-origin: 100% 100%;
        opacity: 0;
      }
      span {
        position: absolute;
        display: block;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fb174b;
        transform: rotate(5deg);
        opacity: 0;
      }
      &.cough2 {
        top: 65px;
        em {
          top: 0;
          transform: skewX(-10deg) scale(0.5) rotate(-20deg);
          transform-origin: 100% 100%;
        }
        span {
          transform: rotate(-10deg);
        }
      }
    }
    &.cough {
      .cough {
        em {
          animation: coughAnimEm 1s;
        }
        span {
          animation: coughAnim 1s;
        }
        &.cough2 {
          em {
            animation: coughAnimEm2 1s;
          }
          span {
            animation: coughAnim2 1s .5s;
          }
        }
      }
    }
    @keyframes coughAnimEm {
      from { opacity: 0; transform: skewX(-10deg) scale(0.5); }
      25% { opacity: 1; }
      to { opacity: 0; transform: skewX(-10deg) scale(1) translate(-10px, 0); }
    }
    @keyframes coughAnimEm2 {
      from { opacity: 0; transform: skewX(-10deg) scale(0.5) rotate(-20deg); }
      25% { opacity: 1; }
      to { opacity: 0; transform: skewX(-10deg) scale(1) rotate(-20deg) translate(-10px, 0); }
    }
    @keyframes coughAnim {
      from { opacity: 0; transform: rotate(5deg); }
      25% { opacity: 1; }
      to { opacity: 0; transform: rotate(5deg) scale(2.5) translate(-50px, 0); }
    }
    @keyframes coughAnim2 {
      from { opacity: 0; transform: rotate(-10deg); }
      25% { opacity: 1; }
      to { opacity: 0; transform: rotate(-10deg) scale(2.5) translate(-50px, 0); }
    }
  }

  
  &.step-1-3 {
    .el-dot {
      opacity: 1;
    }
    .el-triangle {
      transform: scale(1) skew(-10deg, -10deg);
      opacity: 1;
    }
    .el-circle {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  &.step-1-4 {
    .el-circle {
      >div {
        margin: -200px 100px;
        margin: -100px 50px;
      }
      >.el-food {
        top: 170px;
        left: 480px;
      }
      .el-peptide {
        opacity: 1;
      }
    }
  }
  
  &.step-1-5 {
    .el-circle {
      >div {
        margin: -200px 100px;
/*         margin: -150px 75px; */
      }    
      .el-peptide {
        opacity: 1;
        top: 615px;
        left: 230px;
/*         transform: translate(-50px, 50px); */
      }
    }
  }
  
  &.step-1-6 {
    .el-circle {
      background-color: #852368;
      >div {
        margin: -350px 175px;
      }    
      .el-peptide {
        opacity: 1;
        top: 675px;
        left: 145px;
/*         transform: translate(-135px, 140px); */
      }
      .el-icl span {
        opacity: 0;
      }
    }
  }
  &.step-1-6-1 {
    .el-circle {
      .el-icl .immune-response {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  
  
  
  &.step-1-7 {
    .el-circle {
      transform: scale(0);
      visibility: hidden;
    }
    .el-dot {
      opacity: 0;
    }
    .el-triangle {
      transform: scale(0);
      visibility: hidden;
    }
  }
  
  &.step-1-7-1, &.step-1-8 {
    transform: scale(0.25) translateY(-150%);
  }
  
  &.step-1-8 {
    .el-peanut-3 {
      opacity: 0;
    }
    .human {
      .human-sintestine,
      .human-lintestine,
      .human-digestive {
        opacity: 0;
      }
    }
  }
  .human {
    .detail-nervous {
      position: absolute;
      top: 7px;
      left: 7px;
      width: 255px;
      height: 615px;
      background: url("../img/animsvg/01-human-nervous.svg") no-repeat;
      visibility: hidden;
      opacity: 0;
      transition: opacity 1s !important;
    }
    .detail-immune {
      position: absolute;
      top: 37px;
      left: 5px;
      width: 258px;
      height: 584px;
      background: url("../img/animsvg/01-human-immune.svg") no-repeat;
      visibility: hidden;
      opacity: 0;
      transition: opacity 1s !important;
    }
    .detail-microbiome {
      position: absolute;
      top: 225px;
      left: 88px;
      width: 91px;
      height: 103px;
      background: url("../img/animsvg/01-human-microbiome.svg") no-repeat;
      visibility: hidden;
      opacity: 0;
      transition: opacity 1s !important;
    }
    .detail-digestive {
      position: absolute;
      top: 53px;
      left: 97px;
      width: 76px;
      height: 185px;
      background: url("../img/animsvg/01-human-digestive.svg") no-repeat;
      visibility: hidden;
      opacity: 0;
      transition: opacity 1s !important;
    }
  }
  &.step-1-9,
  &.step-1-8-1 {
    .human .detail-nervous {
      &.dn2 { opacity: 0; }
      opacity: 1;
      visibility: visible;
      &.hidden {
        opacity: 0 !important;
        visibility: hidden;
      }
    }
  }
  &.step-1-9,
  &.step-1-8-2 {
    .human .detail-immune {
      &.dn2 { opacity: 0; }
      opacity: 1;
      visibility: visible;
      &.hidden {
        opacity: 0 !important;
        visibility: hidden;
      }
    }
  }
  &.step-1-9,
  &.step-1-8-3 {
    .human .detail-microbiome {
      &.dn2 { opacity: 0; }
      opacity: 1;
      visibility: visible;
      &.hidden {
        opacity: 0 !important;
        visibility: hidden;
      }
    }
  }
  &.step-1-9,
  &.step-1-8-4 {
    .human .detail-digestive {
      &.dn2 { opacity: 0; }
      opacity: 1;
      visibility: visible;
      &.hidden {
        opacity: 0 !important;
        visibility: hidden;
      }
    }
  }
  &.step-1-9,
  &.step-1-8-5 {
    .human {
      transform: scale(4) translateX(-100px);
      svg g#Body-changing {
        fill: #eb1d5b;
      }
      .detail-nervous.dn2 {
        opacity: 1;
        background-image: url("../img/animsvg/03-human-nervous.svg");
      }
      .detail-immune.dn2 {
        opacity: 1;
        background-image: url("../img/animsvg/03-human-immune.svg");
      }
      .detail-microbiome.dn2 {
        opacity: 1;
        background-image: url("../img/animsvg/03-human-microbiome.svg");
      }
      .detail-digestive.dn2 {
        opacity: 1;
        background-image: url("../img/animsvg/03-human-digestive.svg");
      }
    }
    
  }
  
  
}






.step-2 {
  .el-dots {
    em {
      position: absolute;
      width: 10px;
      height: 10px;
      background: rgba(0,0,0,0.4);
      border-radius: 5px;
      transform-origin: 50% 300%;
      animation: step2dots 10s infinite linear;
      &:nth-child(1) {
        left: 20%; top: 79%;
        animation-delay: -3s; animation-duration: 12s;
      }
      &:nth-child(2) {
        left: 35%; top: 80%;
        animation-delay: -6s; animation-duration: 10.5s;
      }
      &:nth-child(3) {
        left: 33%; top: 25%;
        animation-delay: -8s; animation-duration: 15s;
      }
      &:nth-child(4) {
        left: 50%; top: 40%;
        animation-delay: -1s; animation-duration: 11s;
      }
      &:nth-child(5) {
        left: 66%; top: 45%;
        animation-delay: -4s; animation-duration: 13s;
      }
      &:nth-child(6) {
        left: 80%; top: 30%;
        animation-delay: 0s; animation-duration: 14s;
      }
    }
    @keyframes step2dots {
      from { transform: rotate(0deg) translateX(0); }
      25% { transform: rotate(90deg) translateX(50px); }
      50% { transform: rotate(180deg) translateX(-50px); }
      75% { transform: rotate(270deg) translateX(0px); }
      to { transform: rotate(360deg) translateX(0); }
    }
    opacity: 0;
  }
  &.step-2-0 {
    .el-dots {
      opacity: 1;
    }
  }
  
  .immune-response { display: none; }
  .el-icl, .el-neuron {
    opacity: 0;
    position: absolute;
    animation: step2cells 2s infinite alternate linear;
  }
  .el-icl {
    width: 133px; height: 95px; margin: -47px 0 0 -66px;
    .el-peptide {
      background: #fb174b;
      width: 15px;
      height: 15px;
      border-radius: 13px;
      position: absolute;
      top: 17px;
      left: 96px;
    }
  }
  .el-neuron {
    width: 448px; height: 392px; margin: -196px 0 0 -224px;
  }
  .el-icl.i1 {
    top: 50%; left: 60%;
    svg { transform: rotate(10deg); }
    animation-delay: -1s;
    margin: -400px 0 0 -66px;
  }
  .el-icl.i2 {
    top: 70%; left: 70%;
    svg { transform: rotate(10deg) scale(0.8); }
    animation-delay: -2s;
    margin: 447px 0 0 -66px;
    .el-peptide {
      width: 12px;
      height: 12px;
      top: 20px;
      left: 87px;
    }
  }
  .el-neuron.n1 {
    top: 66%; left: 40%;
    animation-delay: -2.9s;
    margin: -196px 0 0 -600px;
  }
  .el-neuron.n2 {
    top: 62%; left: 80%;
    svg { transform: rotate(240deg); }
    animation-delay: -3.7s;
    margin: -196px 0 0 224px;
  }
  .el-neuron.n3 {
    top: 30%; left: 80%;
    svg { transform: rotate(120deg) scale(0.6); }
    animation-delay: -4.6s;
    margin: -496px 0 0 -224px;
  }
  @keyframes step2cells {
    from { transform: rotate(0deg) translate(0, 10px); }
    25% { transform: rotate(5deg) translate(30px, 0); }
    45% { transform: rotate(0deg) translate(-10px, 30px); }
    50% { transform: rotate(0deg) translate(-10px, 30px); }
    55% { transform: rotate(0deg) translate(50px, 30px); }
    75% { transform: rotate(-5deg) translate(10px, -30px); }
    to { transform: rotate(0deg) translate(0, 10px); }
  }
  @keyframes step2cells2 {
    from { transform: rotate(0deg) translate(0, 10px); }
    25% { transform: rotate(25deg) translate(30px, 0); }
    50% { transform: rotate(0deg) translate(-10px, 30px); }
    75% { transform: rotate(-25deg) translate(10px, -30px); }
    to { transform: rotate(0deg) translate(0, 10px); }
  }
  
  &.step-2-1 {
    .el-icl, .el-neuron {
      opacity: 1;
      margin: 0;
    }
    .el-icl {
      margin: -47px 0 0 -66px;
    }
    .el-neuron {
      margin: -196px 0 0 -224px;
    }
  }
  &.step-2-2 {
    .el-dots {
      opacity: 0;
    }
  }
  &.step-2-3 {
    .el-dots {
      opacity: 1;
      em {
        background: rgba(255,255,255,0.4);
      }
    }
  }
  
  .pill {
    .pill-half {
      position: absolute;
      width: 35px;
      height: 30px;
      background: white;
      top: -50px;
      opacity: 0;
    }
    .pill-half.h1 {
      right: 50%;
      border-radius: 15px 0 0 15px;
      transform-origin: 100% 0%;
    }
    .pill-half.h2 {
      left: 50%;
      border-radius: 0 15px 15px 0;
      background: #eedc2c;
      transform-origin: 0% 0%;
    }
    .pill-dot {
      position: absolute;
      width: 10px;
      height: 10px;
      background: white;
      border-radius: 5px;
      top: 11%;
      right: 50%;
      margin-right: -5px;
      opacity: 0;
    }
    .pill-dot.d1 {
    }
    .pill-dot.d2 {
    }
    .pill-dot.d3 {
    }
  }
  
  &.step-2-2 {
    .pill-half {
      top: 10%;
      opacity: 1;
    }
  }
  &.step-2-2-1 {
    .pill-half.h1 {
      transform: rotate(30deg);
    }
    .pill-half.h2 {
      transform: rotate(-30deg);
    }
    .pill-dot {
      opacity: 1;
    }
  }
  &.step-2-2-2 {
    .pill-dot.d1 {
      transform: rotate(-5deg) translateY(200px);
      opacity: 0.6;
    }
    .pill-dot.d2 {
      transform: rotate(5deg) translateY(160px);
      opacity: 0.65;
    }
    .pill-dot.d3 {
      transform: rotate(-2deg) translateY(120px);
      opacity: 0.5;
    }
  }
  &.step-2-2-3 {
    .el-icl, .el-neuron {
      animation-name: step2cells2;
      animation-duration: 15s;
      animation-timing-function: ease;
    }
    .pill-dot.d1 {
      transform: rotate(-5deg) translateY(250px);
      opacity: 0;
    }
    .pill-dot.d2 {
      transform: rotate(5deg) translateY(200px);
      opacity: 0;
    }
    .pill-dot.d3 {
      transform: rotate(-2deg) translateY(144px);
      opacity: 0;
    }
  }
  &.step-2-3 {
    .pill-half {
      opacity: 0;
    }
    .el-icl, .el-neuron {
      animation-name: step2cells2;
      animation-duration: 25s;
      animation-timing-function: ease;
    }
    .el-icl>div, .el-neuron>div {
      transition: 1s;
    }
  }
  &.step-2-4 {
    .el-dots {
      opacity: 0;
      transition: 1s;
    }
    .el-icl>div, .el-neuron>div {
      transform: scale(0);
      transition: 1s;
    }
  }
  
  
  
}



.step-3 {
  
  .mesh-map {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    width: 760px;
    height: 640px;
    transform: scale(0.5);
    transform-origin: 80% 50%;
    canvas {
      position: absolute;
      right: 0;
      top: 0;
      width: 760px;
      height: 640px;
    }
/*     outline: 1px solid rgba(0,0,0,0.1); */
    .person {
      opacity: 0;
      position: absolute;
      background: #2e65b2;
      cursor: pointer;
      width: 80px;
      height: 80px;
      border: 5px solid #1789c8;
      margin: -45px 0 0 -45px;
      overflow: hidden;
      border-radius: 45px;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      z-index: 0;
      transition: .5s !important;
      &.board {
        border-color: #ac3889;
      }
      img {
        width: 100%;
        height: 100%;
        transition: .3s;
        opacity: 1;
        position: relative;
        z-index: 1;
      }
      span {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
      em {
        transform: translateY(30px);
        transition: .3s;
        opacity: 0;
        text-decoration: underline;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        text-align: center;
      }
      &:hover {
        transform: scale(1.3);
        img {
          opacity: 0;
        }
        em {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
    .line {
      background: #3bb2e5;
      height: 0.5px;
      position: absolute;
      transform-origin: 0% 0%;
    }
  }
  
  &.step-2-0 {
    .mesh-map {
      opacity: 1;
    }
  }
  &.step-2-1 {
    .mesh-map {
      transform: scale(1);
      .person {
        opacity: 1;
      }
    }
  }
  
}
