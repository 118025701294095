@font-face {
	font-family: 'DIN';
  		src: url('../fonts/din-regular-webfont.eot?#iefix') format('embedded-opentype'),
  			 url('../fonts/din-regular-webfont.otf') format('opentype'),
  			 url('../fonts/din-regular-webfont.woff') format('woff'),
  			 url('../fonts/din-regular-webfont.ttf') format('truetype'),
  			 url('../fonts/din-regular-webfont.svg#D-DIN') format('svg');
  	font-weight: normal;
  	font-style: normal;
}

@font-face {
	font-family: 'DIN';
  		src: url('../fonts/din-bold-webfont.eot?#iefix') format('embedded-opentype'),
  			 url('../fonts/din-bold-webfont.otf') format('opentype'),
  			 url('../fonts/din-bold-webfont.woff') format('woff'),
  			 url('../fonts/din-bold-webfont.ttf') format('truetype');
  	font-weight: bold;
  	font-style: normal;
}
