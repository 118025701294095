@media screen and (max-width: 990px) and (orientation: portrait) {

  .step-1 .el-egg {
    left: 180px;
    top: 165px;
    background-size: contain;
    width: 97px;
    height: 97px;
  }
  .step-1 .el-milk {
    left: 352px;
    top: 165px;
    width: 167px;
    height: 211px;
    background-size: contain;
  }
  .step-1 .el-peanut {
    left: 358px;
    top: 704px;
  }
  .step-1 .el-peanut-1 {
    left: 471px;
    top: 712px;
  }
  .step-1 .el-peanut-2 {
    left: 430px;
    top: 749px;
  }
  .step-1-1-0 .el-peanut-3,
  .step-1-0-0 .el-peanut-3 {
    left: 484px;
    top: 764px;
  }
  .step-1-1-1 .el-peanut-3 {
    left: 908px;
    top: 495px;
  }
  
  .step-1.step-1-1-2 {
    transform: scale(0.8) translate(-50%, 0);
  }
  .step-1.step-1-1-5,
  .step-1.step-1-2 {
    transform: scale(0.45) translate(-50%, -10%);
  }
  
  .el-triangle-rotate {
    transform: rotate(-46deg);
    transform-origin: 600px 1214px;
  }
  
  .step-1 .el-circle {
    top: 190px;
    left: 220px;
    transform-origin: 50% 135%;
  }
  
  .step-1.step-1-9 .human,
  .step-1.step-1-8-5 .human {
    transform: scale(2) translateX(-10%);
  }
  
  .step-2 .el-neuron.n1 {
    top: 70%;
    left: 35%;
  }
  
  .step-2 .el-neuron.n2 {
    top: 68%;
  }
  
  .step-2 .el-neuron.n3 {
    top: 45%;
  }
  
  .step-2 .el-icl.i1 {
    top: 55%;
    left: 45%;
  }
  
  .step-2 .el-icl.i2 {
    top: 80%;
  }
  
  .step-2 .pill {
    transform: translateY(300px);
  }
  
  .step-3 .mesh-map {
    transform: scale(0.5) translate(150px, -100px);
    transform: scale(0.5) translate(50px, -150px);
  }
  .step-3.step-2-1 .mesh-map {
    transform: scale(0.8) translate(50px, 400px);
  }
  
  .step-3.step-2-1 .mesh-map .person {
    transform: scale(1.2);
  }
  
  .popup {
    padding-left: 50vw;
  }
  
  .step-2 {
    .el-dots {
      em {
        &:nth-child(4) {
          left: 26%; top: 68%;
        }
        &:nth-child(5) {
          left: 66%; top: 65%;
        }
        &:nth-child(6) {
          left: 80%; top: 60%;
        }
      }
    }
  }
  
  
  .close em {
    top: 27px;
    
  }
  .close span {
    top: 37px;
  }
  
  .popup .pop-inner .name span {
    font-size: 20px;
  }
  
}
